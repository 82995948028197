<template>
    <div>
        <b-row class="mt-4 mb-2">
            <b-col sm="6" md="3">
                <b-dropdown text=" Select Actions " variant="dark" slot="append">
                    <b-dropdown-item>
                        <json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
                            Export Asset Recon Summary in Excel</json-excel>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
                            Export Asset Recon Summary to CSV</json-excel>
                    </b-dropdown-item>
                    <b-dropdown-item v-show="!hasAreaParam">
                        <json-excel :data="exportPerRegionOrAreaData" :fields="exportPerRegionOrAreaFields" type="xls"
                            :name="perRegionFileName + '.xls'">
                            Export Asset Recon Per Region in Excel</json-excel>
                    </b-dropdown-item>
                    <b-dropdown-item v-show="!hasAreaParam">
                        <json-excel :data="exportPerRegionOrAreaData" :fields="exportPerRegionOrAreaFields" type="csv"
                            :name="perRegionFileName + '.csv'">
                            Export Asset Recon Per Region to CSV</json-excel>
                    </b-dropdown-item>
                    <b-dropdown-item v-show="hasAreaParam && !hasCompanyParam">
                        <json-excel :data="exportPerRegionOrAreaData" :fields="exportPerRegionOrAreaFields" type="xls"
                            :name="perAreaFileName + '.xls'">
                            Export Asset Recon Per Area in Excel</json-excel>
                    </b-dropdown-item>
                    <b-dropdown-item v-show="hasAreaParam && !hasCompanyParam">
                        <json-excel :data="exportPerRegionOrAreaData" :fields="exportPerRegionOrAreaFields" type="csv"
                            :name="perAreaFileName + '.csv'">
                            Export Asset Recon Per Area to CSV</json-excel>
                    </b-dropdown-item>
                </b-dropdown>
            </b-col>
            <b-col sm="6" md="3" offset-md="6" class="my-1 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>

            <template v-slot:cell(totalMatchedLocation)="row">
                <div class="clearfix">
                    <div class="float-left">
                        <strong>{{ getMatchedLocPercentage(row.item) }}</strong>
                    </div>
                    <div class="float-right">
                        <small class="text-muted">
                            {{ row.item.totalMatchedLocation }} / {{ row.item.totalAyunAssets }}
                        </small>
                    </div>
                </div>
                <b-progress variant="success" :value="(row.item.totalMatchedLocation / row.item.totalAyunAssets) * 100"
                    class="progress-xs" />
            </template>

            <template v-slot:cell(totalUnmatchedLocation)="row">
                <div class="clearfix">
                    <div class="float-left">
                        <strong>{{ getUnMatchedLocPercentage(row.item) }}</strong>
                    </div>
                    <div class="float-right">
                        <small class="text-muted">
                            {{ row.item.totalUnmatchedLocation }} / {{ row.item.totalAyunAssets }}
                        </small>
                    </div>
                </div>
                <b-progress variant="danger" :value="(row.item.totalUnmatchedLocation / row.item.totalAyunAssets) * 100"
                    class="progress-xs" />
            </template>

            <template v-slot:cell(totalMatchedAccountability)="row">
                <div class="clearfix">
                    <div class="float-left">
                        <strong>{{ getMatchedAccountabilityPercentage(row.item) }}</strong>
                    </div>
                    <div class="float-right">
                        <small class="text-muted">
                            {{ row.item.totalMatchedAccountability }} / {{ row.item.totalMatchedLocation }}
                        </small>
                    </div>
                </div>
                <b-progress variant="success"
                    :value="(row.item.totalMatchedAccountability / row.item.totalMatchedLocation) * 100"
                    class="progress-xs" />
            </template>

            <template v-slot:cell(totalUnmatchedAccountability)="row">
                <div class="clearfix">
                    <div class="float-left">
                        <strong>{{ getUnmatchedAccountabilityPercentage(row.item) }}</strong>
                    </div>
                    <div class="float-right">
                        <small class="text-muted">
                            {{ row.item.totalUnmatchedAccountability }} / {{ row.item.totalMatchedLocation }}
                        </small>
                    </div>
                </div>
                <b-progress variant="danger"
                    :value="(row.item.totalUnmatchedAccountability / row.item.totalMatchedLocation) * 100"
                    class="progress-xs" />
            </template>

            <template v-slot:cell(actions)="row">
                <span class="text-nowrap">
                    <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
                        @click.stop="row.toggleDetails" class="mr-1 mt-1">
                        <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
                        <i class="fa fa-eye" v-else></i>
                    </b-button>
                </span>
            </template>

            <template slot="row-details" slot-scope="row">
                <AssetReconPerCompany :row="row" />
            </template>

        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

        <b-row class="mt-4 mb-2">
            <b-col sm="12" class="text-sm-right">
                <b-button variant="secondary" @click="onBack()">
                    Back
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Components
import AssetReconPerCompany from "./AssetReconPerCompany";

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
    name: 'asset-recon-processed-data',
    components: {
        AssetReconPerCompany,
        JsonExcel
    },
    props: {
        reconPerCompany: {
            type: Array,
            required: true,
        },
        reconParams: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'company',
                    sortable: true
                },
                {
                    key: 'branchCode',
                    sortable: true
                },
                {
                    key: 'totalMatchedLocation',
                    label: 'Matched Location'
                },
                {
                    key: 'totalUnmatchedLocation',
                    label: 'Unmatched Location'
                },
                {
                    key: 'totalMatchedAccountability',
                    label: 'Matched Accountability'
                },
                {
                    key: 'totalUnmatchedAccountability',
                    label: 'Unmatched Accountability'
                },
                {
                    key: 'totalDummyAssets',
                    label: 'Dummy Assets'
                },
                {
                    key: 'totalAyunAssets',
                    label: 'Ayun! Assets'
                },
                {
                    key: 'totalNonAyunAssets',
                    label: 'Non-Ayun! Assets'
                },
                'actions'
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null
        }
    },
    watch: {
        "reconPerCompany": function (newVal) {
            if (!_.isEmpty(newVal)) {
                this.items = newVal;
                this.totalRows = _.size(this.items);
            }
        },
    },
    computed: {
        hasAreaParam() {
            let reconParams = this.reconParams ? this.reconParams : {};
            let areaParam = reconParams.area && reconParams.area.length > 0 ? reconParams.area : '';
            return areaParam.length > 0;
        },
        hasCompanyParam() {
            let reconParams = this.reconParams ? this.reconParams : {};
            let companyParam = reconParams.company ? reconParams.company : {};
            return companyParam.id !== null;
        },

        region() {
            let reconParams = this.reconParams ? this.reconParams : {};
            return reconParams.region ? reconParams.region : '-';
        },
        area() {
            let reconParams = this.reconParams ? this.reconParams : {};
            return reconParams.area ? reconParams.area : '-';
        },

        /**
         * Returns the set of data to be included in the export. For now this just
         * returns the data as is.
         *
         * @returns {Array} the set of data to be included in the export.
         */
        exportData() {
            _.forEach(this.items, item => {
                if (item.branchCode) {
                    item['Branch Code'] = "'" + item.branchCode;
                } else {
                    item['Branch Code'] = "";
                }
            });
            return this.items;
        },

        /**
         * Derives the field information based from the data table configuration.
         *
         * @returns {object} the fields to be included in the export.
         */
        exportFields() {
            return {
                'Company': 'company',
                'Branch Code': 'Branch Code',
                'Matched Location': 'totalMatchedLocation',
                'Unmatched Location': 'totalUnmatchedLocation',
                'Matched Accountability': 'totalMatchedAccountability',
                'Unmatched Accountability': 'totalUnmatchedAccountability',
                'Dummy Assets': 'totalDummyAssets',
                'Total Ayun Assets': 'totalAyunAssets',
                'Total Non-Ayun Assets': 'totalNonAyunAssets'
            };
        },

        exportPerRegionOrAreaData() {
            let perRegionOrAreaItems = [];

            _.forEach(this.reconPerCompany, item => {
                let reconAssets = item.reconAssets;

                _.forEach(reconAssets, assetItem => {
                    perRegionOrAreaItems.push(assetItem);
                });
            });

            _.forEach(perRegionOrAreaItems, item => {
                if (item.branchCode && item.branchCode.length > 1 && item.branchCode !== '-') {
                    item['Branch Code in Ayun'] = "'" + item.branchCode;
                } else {
                    item['Branch Code in Ayun'] = "-";
                }

                if (item.branchCodeInNonAyun && item.branchCodeInNonAyun.length > 1 && item.branchCodeInNonAyun !== '-') {
                    item['Branch Code in Non-Ayun'] = "'" + item.branchCodeInNonAyun;
                } else {
                    item['Branch Code in Non-Ayun'] = "-";
                }

                if (item.userInAyun && item.userInAyun.length > 1 && item.userInAyun !== '-') {
                    item['User in Ayun'] = "'" + item.userInAyun;
                } else {
                    item['User in Ayun'] = "-";
                }

                if (item.userInNonAyun && item.userInNonAyun.length > 1 && item.userInNonAyun !== '-') {
                    item['User in Non-Ayun'] = "'" + item.userInNonAyun;
                } else {
                    item['User in Non-Ayun'] = "-";
                }
            });

            return perRegionOrAreaItems;
        },

        exportPerRegionOrAreaFields() {
            return {
                'Asset Code': 'assetCode',
                'Description in Ayun!': 'description',
                'Description in Non-Ayun!': 'nonAyunDescription',
                'Condition': 'condition',
                'Branch Code in Ayun!': 'Branch Code in Ayun',
                'Branch Code in Non-Ayun!': 'Branch Code in Non-Ayun',
                'Exists in Ayun!': 'existsInAyun',
                'Exists in Non-Ayun!': 'existsInNonAyun',
                'Matched Location?': 'matchedLocation',
                'User in Ayun!': 'User in Ayun',
                'User in Non-Ayun!': 'User in Non-Ayun',
                'Matched Accountability?': 'matchedAccountability',
            };
        },

        fileName() {
            let fileName = 'AssetRecon_Summary';

            if (this.region.length > 0) {
                fileName = fileName + '_' + this.region;
            }
            if (this.area.length > 0) {
                fileName = fileName + '_' + this.area;
            }

            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return fileName + '_' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
        },

        perRegionFileName() {
            let fileName = 'AssetRecon_PerRegion_' + this.region + '_';
            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return fileName + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
        },

        perAreaFileName() {
            let fileName = 'AssetRecon_PerArea_' + this.region + '_' + this.area + '_';
            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return fileName + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
        },
    },
    methods: {
        onBack() {
            this.$emit('onBack');
        },
        getMatchedLocPercentage(item) {
            if (item.totalAyunAssets > 0) {
                let percentage = (item.totalMatchedLocation / item.totalAyunAssets) * 100;
                percentage = percentage.toFixed(2);
                return percentage + "%";
            }
            return "0.00%";

        },
        getUnMatchedLocPercentage(item) {
            if (item.totalAyunAssets > 0) {
                let percentage = (item.totalUnmatchedLocation / item.totalAyunAssets) * 100;
                percentage = percentage.toFixed(2);
                return percentage + "%";
            }
            return "0.00%";
        },
        getMatchedAccountabilityPercentage(item) {
            if (item.totalMatchedLocation > 0) {
                let percentage = (item.totalMatchedAccountability / item.totalMatchedLocation) * 100;
                percentage = percentage.toFixed(2);
                return percentage + "%";
            }
            return "0.00%";
        },
        getUnmatchedAccountabilityPercentage(item) {
            if (item.totalMatchedLocation > 0) {
                let percentage = (item.totalUnmatchedAccountability / item.totalMatchedLocation) * 100;
                percentage = percentage.toFixed(2);
                return percentage + "%";
            }
            return "0.00%";
        }
    }
}

</script>

<style scoped></style>