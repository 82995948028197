<template>
    <b-card no-body>
        <div class="mx-4 my-4">
            <b-row class="my-2">
                <b-col sm="8">
                    <span class="title-block">{{ title }}</span>
                    <div class="instruction">
                        <i class="icon-info"></i>
                        Note that only <b>VALID</b> rows from imported file are included in this asset recon processing.
                    </div>
                </b-col>
            </b-row>

            <b-row class="mt-4" no-gutters>
                <b-col lg="4" md="6" sm="12" class="mr-4">
                    <b-form-group label="Search" label-for="Prefix" description="Search by asset code here">
                        <b-form-input name="Asset Code" type="text" v-model="filterBy.assetCode" maxlength="30"
                            placeholder="Asset Code" />
                        <span v-show="errors.has('Asset Code')" class="help-block">{{
                            errors.first('Asset Code')
                        }}</span>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-1" no-gutters>
                <b-col lg="4" md="6" sm="12" class="mr-4">
                    <b-form-group label="Match Location">
                        <v-select class="style-chooser" label="text" :options="statusOptions"
                            :reduce="(status) => status.value" v-model="filterBy.matchedLocation">
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                    No results found for
                                    <em>
                                        <strong>{{ search }}</strong>
                                    </em>
                                </template>
                                <em :style="{ opacity: 0.5 }" v-else>
                                    Start typing to search for status
                                </em>
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>
                <b-col lg="4" md="6" sm="12" class="mr-4">
                    <b-form-group label="Match Accountability">
                        <v-select class="style-chooser" label="text" :options="statusOptions"
                            :reduce="(status) => status.value" v-model="filterBy.matchedAccountability">
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                    No results found for
                                    <em>
                                        <strong>{{ search }}</strong>
                                    </em>
                                </template>
                                <em :style="{ opacity: 0.5 }" v-else>
                                    Start typing to search for status
                                </em>
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mb-4" no-gutters>
                <b-col sm="12">
                    <b-button class="mr-1" variant="success" @click="onFilter">
                        Search
                    </b-button>
                    <b-button class="mr-1" variant="primary" @click="resetFilter">
                        Reset
                    </b-button>
                </b-col>
            </b-row>

            <b-row class="mt-4 mb-2">
                <b-col sm="6" md="3" class="my-1">
                    <b-dropdown text=" Select Actions " variant="dark" slot="append">
                        <b-dropdown-item>
                            <json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
                                Export Asset Recon Per Company in Excel</json-excel>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
                                Export Asset Recon Per Company to CSV</json-excel>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col sm="6" md="3" offset-md="6" class="my-1 text-md-right">
                    <b-input-group prepend="Show" append="/ Page">
                        <b-form-select :options="pageOptions" v-model="perPage" />
                    </b-input-group>
                </b-col>
            </b-row>

            <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
                :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" responsive>

                <template v-slot:cell(existsInAyun)="row">
                    <span v-if="row.item.existsInAyun === 'Yes'">
                        {{ row.item.existsInAyun }}
                    </span>
                    <span v-else class="no-block">
                        {{ row.item.existsInAyun }}
                    </span>
                </template>

                <template v-slot:cell(existsInNonAyun)="row">
                    <span v-if="row.item.existsInNonAyun === 'Yes'">
                        {{ row.item.existsInNonAyun }}
                    </span>
                    <span v-else class="no-block">
                        {{ row.item.existsInNonAyun }}
                    </span>
                </template>

                <template v-slot:cell(matchedLocation)="row">
                    <span v-if="row.item.matchedLocation === 'Matched'">
                        <b-badge variant="success">{{ row.item.matchedLocation }}</b-badge>
                    </span>
                    <span v-else-if="row.item.matchedLocation === 'Unmatched'">
                        <b-badge variant="danger">{{ row.item.matchedLocation }}</b-badge>
                    </span>
                    <span v-else>
                        <b-badge variant="secondary">{{ row.item.matchedLocation }}</b-badge>
                    </span>
                </template>

                <template v-slot:cell(matchedAccountability)="row">
                    <span v-if="row.item.matchedAccountability === 'Matched'">
                        <b-badge variant="success">{{ row.item.matchedAccountability }}</b-badge>
                    </span>
                    <span v-else-if="row.item.matchedAccountability === 'Unmatched'">
                        <b-badge variant="danger">{{ row.item.matchedAccountability }}</b-badge>
                    </span>
                    <span v-else>
                        <b-badge variant="secondary">{{ row.item.matchedAccountability }}</b-badge>
                    </span>
                </template>

            </b-table>

            <b-row>
                <b-col md="8" sm="12" class="my-1">
                    <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                </b-col>
                <b-col md="4" sm="12" class="my-1">
                    <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                        class="my-0" />
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
    name: 'asset-recon-per-company',
    components: {
        JsonExcel
    },
    props: {
        row: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            items: [],
            fields: [
                'assetCode',
                'description',
                'condition',
                {
                    key: 'branchCode',
                    label: 'Branch Code in Ayun!'
                },
                {
                    key: 'branchCodeInNonAyun',
                    label: 'Branch Code in Non-Ayun!'
                },
                {
                    key: 'existsInAyun',
                    label: 'Exists in Ayun!'
                },
                {
                    key: 'existsInNonAyun',
                    label: 'Exists in Non-Ayun!'
                },
                {
                    key: 'matchedLocation',
                    label: 'Matched Location?'
                },
                {
                    key: 'userInAyun',
                    label: 'User in Ayun!'
                },
                {
                    key: 'userInNonAyun',
                    label: 'User in Non-Ayun!'
                },
                {
                    key: 'matchedAccountability',
                    label: 'Matched Accountability?'
                },
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            reconAssets: {},
            title: '',

            defaultFilterBy: {
                assetCode: '',
                matchedLocation: '',
                matchedAccountability: ''
            },
            filterBy: {
                assetCode: '',
                matchedLocation: '',
                matchedAccountability: ''
            },

            statusOptions: [
                { text: ' - Please select - ', value: '' },
                { text: 'Matched', value: "Matched" },
                { text: 'Unmatched', value: "Unmatched" },
            ],
        }
    },
    watch: {
        "row": function (newVal) {
            if (!_.isEmpty(newVal)) {
                this.reconAssets = newVal.item.reconAssets;
                this.items = Object.values(this.reconAssets);
                this.totalRows = _.size(this.items);
                this.title = this.getTitle();
            }
        },
    },
    computed: {
        /**
         * Returns the set of data to be included in the export. For now this just
         * returns the data as is.
         *
         * @returns {Array} the set of data to be included in the export.
         */
        exportData() {
            _.forEach(this.items, item => {
                if (item.branchCode && item.branchCode.length > 1 && item.branchCode !== '-') {
                    item['Branch Code in Ayun'] = "'" + item.branchCode;
                } else {
                    item['Branch Code in Ayun'] = "-";
                }

                if (item.branchCodeInNonAyun && item.branchCodeInNonAyun.length > 1 && item.branchCodeInNonAyun !== '-') {
                    item['Branch Code in Non-Ayun'] = "'" + item.branchCodeInNonAyun;
                } else {
                    item['Branch Code in Non-Ayun'] = "-";
                }

                if (item.userInAyun && item.userInAyun.length > 1 && item.userInAyun !== '-') {
                    item['User in Ayun'] = "'" + item.userInAyun;
                } else {
                    item['User in Ayun'] = "-";
                }

                if (item.userInNonAyun && item.userInNonAyun.length > 1 && item.userInNonAyun !== '-') {
                    item['User in Non-Ayun'] = "'" + item.userInNonAyun;
                } else {
                    item['User in Non-Ayun'] = "-";
                }
            });
            return this.items;
        },

        /**
         * Derives the field information based from the data table configuration.
         *
         * @returns {object} the fields to be included in the export.
         */
        exportFields() {
            return {
                'Asset Code': 'assetCode',
                'Description in Ayun!': 'description',
                'Description in Non-Ayun!': 'nonAyunDescription',
                'Condition': 'condition',
                'Branch Code in Ayun!': 'Branch Code in Ayun',
                'Branch Code in Non-Ayun!': 'Branch Code in Non-Ayun',
                'Exists in Ayun!': 'existsInAyun',
                'Exists in Non-Ayun!': 'existsInNonAyun',
                'Matched Location?': 'matchedLocation',
                'User in Ayun!': 'User in Ayun',
                'User in Non-Ayun!': 'User in Non-Ayun',
                'Matched Accountability?': 'matchedAccountability',
            };
        },

        fileName() {
            let fileName = 'AssetRecon_PerCompany_' + this.row.item.branchCode + '_';
            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return fileName + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
        },
    },
    mounted() {
        if (!_.isEmpty(this.row)) {
            this.reconAssets = this.row.item.reconAssets;
            this.items = Object.values(this.reconAssets);
            this.totalRows = _.size(this.items);
            this.title = this.getTitle();
        }
    },
    methods: {
        getTitle() {
            let company = this.row && this.row.item.company ? this.row.item.company : '';
            if (company.length > 0) {
                return company + ' Asset Recon';
            }
            return 'Asset Recon Per Company';
        },
        resetFilter() {
            this.filterBy = { ...this.defaultFilterBy };
            this.onFilter();
        },
        onFilter() {
            this.items = _.filter(this.reconAssets, o => {
                let isIncluded = true;

                if (this.filterBy.assetCode && this.filterBy.assetCode.length > 0 && !_.includes(o.assetCode, this.filterBy.assetCode)) {
                    isIncluded = false;
                }
                if (this.filterBy.matchedLocation.length > 0 && this.filterBy.matchedLocation !== o.matchedLocation) {
                    isIncluded = false;
                }
                if (this.filterBy.matchedAccountability.length > 0 && this.filterBy.matchedAccountability !== o.matchedAccountability) {
                    isIncluded = false;
                }

                return isIncluded;
            });
            this.totalRows = _.size(this.items);
        },
    }
}
</script>

<style scoped>
.no-block {
    color: #f44335;
}

.title-block {
    font-weight: bold;
    font-size: 18px;
}

.instruction {
    color: #F18F01;
    font-size: 12px;
}
</style>